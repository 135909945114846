<template>
  <div class="row rest">
    <div class="rest aside-bg"></div>
    <VoiceSnb class="left-container" />
    <article class="right-container">
      <hgroup><h1 id="credential1">Credential 추가</h1></hgroup>
      <h2 class="api-url">POST http://localhost:8009/credential</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
            <h3>Message</h3>
            <pre><code class='hljs'>{
    "name": "sample_credential",
    "credential": {
        "type": "service_account",
        "project_id": "damda-XXXX",
        "private_key_id": "...",
        "private_key": "--BEGIN PRIVATE KEY--...--END PRIVATE KEY--\n",
        "client_email": "...",
        "client_id": "...",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "..."
    }
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="credential2">Credential 삭제</h1></hgroup>
      <h2 class="api-url">POST http://localhost:8009/credential/{name}</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
            <h3>Parameters</h3>
            <table class="simple-table object">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Required</th>
              </tr>
              <tr>
                <td>name</td>
                <td>String</td>
                <td>credential 이름</td>
                <td>O</td>
              </tr>
            </table>

          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td style="width: 800px">
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="text1">음성 Text 입력 + Text/TTS 출력</h1></hgroup>
      <h2 class="api-url">POST http://localhost:8009/nlu/dialogflow/text/{response_type}</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
          <h3>Parameters</h3>
          <table class="simple-table object">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Description</th>
              <th>Required</th>
            </tr>
            <tr>
              <td>response_type</td>
              <td>String</td>
              <td>text | tts</td>
              <td>O</td>
            </tr>
          </table>
            <h3>Message</h3>
            <pre><code class='hljs'>{
    "project_id": "XXX-1234",
    "session_id": "123456",
    "language_code": "ko",
    "text": "된장찌개 레시피 알려줘",
    "credential": "sample_credential"   # credential 이름
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{
    "intent": "Recipe Search Intent",
    "parameters": {
       "food": "된장찌개"
    },
    "response_text": "레시피를 알려드릴게요.",
    "response_file": "123456_output.wav"
}</code></pre>
            <table class="simple-table object">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Optional</th>
              </tr>
              <tr>
                <td>intent</td>
                <td>String</td>
                <td>인텐트</td>
                <td>X</td>
              </tr>
              <tr>
                <td>parameters</td>
                <td>Dict</td>
                <td>파리미터</td>
                <td>X</td>
              </tr>
              <tr>
                <td>response_text</td>
                <td>String</td>
                <td>응답 메시지</td>
                <td>X</td>
              </tr>
              <tr>
                <td>response_file</td>
                <td>String</td>
                <td>response_type이 tts일 경우에만 있음</td>
                <td>O</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="text2">음성 STT 입력 + Text/TTS 출력</h1></hgroup>
      <h2 class="api-url">POST http://localhost:8009/nlu/dialogflow/text/{response_type}</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
            <h3>Parameters</h3>
            <table class="simple-table object">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Required</th>
              </tr>
              <tr>
                <td>response_type</td>
                <td>String</td>
                <td>text | tts</td>
                <td>O</td>
              </tr>
            </table>
            <h3>Message</h3>
            <pre><code class='hljs'>{
    "project_id": "XXX-1234",
    "session_id": "123456",
    "language_code": "ko",
    "credential": "sample_credential",
    "rate": 48000   # 44100, default: 48000, rate는 optional한 값입니다.
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{
    "intent": "Recipe Search Intent",
    "parameters": {
       "food": "된장찌개"
    },
    "response_text": "레시피를 알려드려요."
}</code></pre>
            <table class="simple-table object">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Optional</th>
              </tr>
              <tr>
                <td>intent</td>
                <td>String</td>
                <td>인텐트</td>
                <td>X</td>
              </tr>
              <tr>
                <td>parameters</td>
                <td>Dict</td>
                <td>파리미터</td>
                <td>X</td>
              </tr>
              <tr>
                <td>response_text</td>
                <td>String</td>
                <td>응답 메시지</td>
                <td>X</td>
              </tr>
              <tr>
                <td>response_file</td>
                <td>String</td>
                <td>response_type이 tts일 경우에만 있음</td>
                <td>O</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="text3">음성 Stream 입력 + Text 출력</h1></hgroup>
      <h2 class="api-url">ws://localhost:8009/nlu/dialogflow/stream/text</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
            <h3>Message</h3>
            <pre><code class='hljs'>{
    "project_id": "XXX-1234",
    "session_id": "123456",
    "language_code": "ko",
    "credential": "sample_credential",
    "rate": 48000   # 44100, default: 48000, rate는 optional한 값입니다.
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{response_type: 'intermediate_transcript', text: '된장'}
{response_type: 'intermediate_transcript', text: '된장찌개'}
{response_type: 'intermediate_transcript', text: '된장찌개'}
{response_type: 'intermediate_transcript', text: '된장찌개 레시피'}
{response_type: 'intermediate_transcript', text: '된장찌개 레시피 알려'}
{response_type: 'intermediate_transcript', text: '된장찌개 레시피 알려 줘'}
{response_type: 'intermediate_transcript', text: '된장찌개 레시피 알려 줘'}
{response_type: 'intermediate_transcript', text: '된장찌개 레시피 알려 줘'}
{response_type: 'intermediate_transcript', text: ''}
{response_type: 'query_result',
    result: {
        intent: "Recipe Search Intent",
        parameters: {food: '된장찌개'},
        response_text: "레시피를 알려드릴게요."
    }
}</code></pre>
            response_type<br>
            1) intermediate_transcript: 중간 발화의  stt 결과를 반환<br>
            2) query_result: 발화가 끝난 후 NLU 결과를 반환 (인텐트, 파라미터, 응답 메시지)<br>
          </td>
        </tr>
        <tr>
          <th>호출 예시</th>
          <td>
            javascript<br>
            <pre><code class='hljs'>var deployMonitorSocket = new WebSocket(
  "ws://localhost:8008/nlu/dialogflow/stream/text"
);
deployMonitorSocket.onopen = function (event) {
    var msg = JSON.stringify({
        'project_id': "damda-XXXX",
        'session_id': "123456",
        "credential": "/path-to-credential/XXX.json"
    });
    deployMonitorSocket.send(msg);
};
deployMonitorSocket.onmessage = function (event) {
    data = JSON.parse(event.data);
    console.log(data);
}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="record">녹음</h1></hgroup>
      <h2 class="api-url">POST http://localhost:8009/audio/record</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
            <h3>Message1</h3>: 음성 파일을 기본 폴더에 저장합니다.
            <pre><code class='hljs'>{
    "file_name": "test.wav"
}</code></pre>
            <h3>Message2</h3>: 음성 파일을 특정 경로에 저장합니다.
            <pre><code class='hljs'>{
   "file_path": "/Users/jimin/Desktop/hi.wav"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{
   "file_name": "test.wav"
}</code></pre>
            <pre><code class='hljs'>{
   "file_path": "/Users/jimin/Desktop/hi.wav"
}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="play">재생</h1></hgroup>
      <h2 class="api-url">POST http://localhost:8009/audio/play</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
            <h3>Message1</h3>: 음성 파일을 기본 폴더에 있는 음성파일을 재생합니다.
            <pre><code class='hljs'>{
   "file_name": "test.wav"
}</code></pre>
            <h3>Message2</h3>: 특정 경로의 음성파일을 재생합니다.
            <pre><code class='hljs'>{
   "file_path": "/Users/jimin/Desktop/hi.wav"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="delete">음성파일 삭제</h1></hgroup>
      <h2 class="api-url">DELETE http://localhost:8009/audio/record/{file_name}</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Request</th>
          <td>
          <h3>Parameters</h3>
          <table class="simple-table object">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Description</th>
              <th>Required</th>
            </tr>
            <tr>
              <td>file_name</td>
              <td>String</td>
              <td>기본 폴더에 있는 음성 파일 중 제거할 음성파일의 이름<br>
                예시) test.wav</td>
              <td>O</td>
            </tr>
          </table>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{
   "file_name": "test.wav"
}</code></pre>
          </td>
        </tr>
      </table>
    </article>
    <div class="rest"></div>
  </div>
</template>
<script>
import VoiceSnb from "./VoiceSnb.vue";
export default {
  components: {
    VoiceSnb,
  },
  data() {
    return {
      isOpenErrorCodeList: false,
    };
  },
  methods: {
    showErrorCodeList() {
      this.isOpenErrorCodeList = true;
      this.$refs.errorCodeList.classList.remove("hide");
    },
    hideErrorCodeList() {
      this.isOpenErrorCodeList = false;
      this.$refs.errorCodeList.classList.add("hide");
      location.hash = "#errorCodeList";
    },
  },
};
</script>
